$assets-path: '../../assets' !default;

@mixin hover {
    &:hover {
        @media (-ms-high-contrast: none),
            (-ms-high-contrast: active),
            (-moz-touch-enabled: 0),
            (hover) {
            @content;
        }
    }
}

@mixin themed(
    $property,
    $properties: ('light', 'dark'),
    $dark-mode-selector: '.dark-mode'
) {
    @if (map-get($properties, light)) {
        #{$property}: map-get($properties, light);
    }

    @if (map-get($properties, dark)) {
        @at-root #{$dark-mode-selector} & {
            #{$property}: map-get($properties, dark);
        }
    }
}

@mixin flex(
    $align: stretch,
    $justify: space-between,
    $wrap: nowrap,
    $direction: row,
    $display: flex
) {
    display: $display;
    flex-flow: $direction $wrap;
    align-items: $align;
    justify-content: $justify;
}

@function v($var, $fallback: null) {
    @if ($fallback) {
        @return var(--#{$var}, #{$fallback});
    } @else {
        @return var(--#{$var});
    }
}

/**
    MUI Default Breakpoints ** start ** at:
    - xs: 0
    - sm: 600px (37.5rem)
    - md: 900px (56.25rem)
    - lg: 1200px (75rem)
    - xl: 1536px (96rem)
*/

$breakpoint-xs: 0;
$breakpoint-sm: 37.5rem;
$breakpoint-md: 56.25rem;
$breakpoint-lg: 75rem;
$breakpoint-xl: 96rem;
$breakpoint-xxl: 110rem;

@mixin large-desktop {
    @media (max-width: calc($breakpoint-xl - 1px)) {
        @content;
    }
}

@mixin xlarge-desktop {
    @media (max-width: calc($breakpoint-xxl - 1px)) {
        @content;
    }
}

@mixin medium-desktop {
    @media (max-width: calc($breakpoint-lg - 1px)) {
        @content;
    }
}

@mixin tablet {
    @media (max-width: calc($breakpoint-md - 1px)) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: calc($breakpoint-sm - 1px)) {
        @content;
    }
}

// TODO - move to the shared library: EONFEH-15903
@mixin app-link($color: v(color-primary)) {
    color: $color;
    font-weight: bold;
    text-decoration: underline;

    @include hover {
        text-decoration: none;
    }
}

@mixin section-title {
    font-weight: 300;
    font-size: 1.625rem;
    margin: 1.25rem 0;
}

@mixin cancel-button {
    color: v(color-white);
    background-color: v(color-grey-button);
}

@mixin cancel-button-hover {
    background-color: v(color-grey-button);
}

@mixin animated-cancel-button {
    @include cancel-button;

    background: linear-gradient(
        to right,
        v(color-grey-hover) 50%,
        v(color-grey-button) 50%
    );
    background-size: 200% 100%;
    background-position: right bottom;
    transition: all ease 1s;

    &:hover {
        @include cancel-button-hover;
        background-position: left bottom;
    }
}

@mixin close-button-position {
    position: absolute;
    top: 0;
    right: 0;
}

@mixin auto-height {
    height: auto !important;
}

.header {
    height: 5.625rem;
    @include flex(center, flex-start);
    padding: 1rem 0.5rem 1rem 1rem;

    &__left,
    &__right {
        @include flex(center, flex-start);
    }

    &__left {
        transition: margin-left v(timing) v(easing);
    }

    &__right {
        margin-left: auto;
    }

    &__search {
        flex: 1;
        max-width: 50rem;
    }

    &__middle {
        flex: 1;
        position: relative;
        padding: 0 0.5rem 0 3rem;
    }

    &__searchfield {
        background-color: v(color-white);
    }
}

.logo {
    min-width: 7.375rem;
    display: block;
    margin-right: 3.5rem;
}

.select {
    border-radius: 0;
    margin-right: 3.25rem;

    svg {
        fill: v(color-white);
        margin-right: 0.25rem;
        margin-top: -0.125rem;
    }

    fieldset {
        border: 0;
    }

    [role='button'] {
        font-size: 0;

        span {
            font-size: 2rem !important;
            line-height: 1 !important;
        }
    }
}

.dropdown {
    li {
        @include auto-height;
    }

    span[role='img'] {
        margin-right: 0.75rem;
    }
}

.health {
    margin: 0 0.5rem;
}

.badge {
    color: v(color-white);

    span {
        border: 1px solid;
        background-color: v(color-red);
    }
}

.account {
    color: v(color-white);
    text-decoration: none;
    @include flex(center, center);
}

.avatar {
    color: v(color-red);
    background-color: v(color-white);
    margin-left: 0.5rem;
    font-size: 1rem;
    font-weight: 500;

    svg {
        width: 1.25rem;
        height: auto;
        display: block;
        fill: currentColor;
    }
}

.logout {
    font-size: 100%;
    color: v(color-white);
    text-transform: none;
    margin-left: 0.5rem;

    svg {
        margin-left: 0.5rem;
    }
}

.back {
    color: v(color-white);
    min-width: 0;
    width: 2.5rem;
    padding: 0;
    display: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    bottom: 0;
    left: 0;

    svg {
        width: 2.5rem;
        height: 2.5rem;
    }
}
