$assets-path: '../../assets' !default;

@mixin hover {
    &:hover {
        @media (-ms-high-contrast: none),
            (-ms-high-contrast: active),
            (-moz-touch-enabled: 0),
            (hover) {
            @content;
        }
    }
}

@mixin themed(
    $property,
    $properties: ('light', 'dark'),
    $dark-mode-selector: '.dark-mode'
) {
    @if (map-get($properties, light)) {
        #{$property}: map-get($properties, light);
    }

    @if (map-get($properties, dark)) {
        @at-root #{$dark-mode-selector} & {
            #{$property}: map-get($properties, dark);
        }
    }
}

@mixin flex(
    $align: stretch,
    $justify: space-between,
    $wrap: nowrap,
    $direction: row,
    $display: flex
) {
    display: $display;
    flex-flow: $direction $wrap;
    align-items: $align;
    justify-content: $justify;
}

@function v($var, $fallback: null) {
    @if ($fallback) {
        @return var(--#{$var}, #{$fallback});
    } @else {
        @return var(--#{$var});
    }
}

/**
    MUI Default Breakpoints ** start ** at:
    - xs: 0
    - sm: 600px (37.5rem)
    - md: 900px (56.25rem)
    - lg: 1200px (75rem)
    - xl: 1536px (96rem)
*/

$breakpoint-xs: 0;
$breakpoint-sm: 37.5rem;
$breakpoint-md: 56.25rem;
$breakpoint-lg: 75rem;
$breakpoint-xl: 96rem;
$breakpoint-xxl: 110rem;

@mixin large-desktop {
    @media (max-width: calc($breakpoint-xl - 1px)) {
        @content;
    }
}

@mixin xlarge-desktop {
    @media (max-width: calc($breakpoint-xxl - 1px)) {
        @content;
    }
}

@mixin medium-desktop {
    @media (max-width: calc($breakpoint-lg - 1px)) {
        @content;
    }
}

@mixin tablet {
    @media (max-width: calc($breakpoint-md - 1px)) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: calc($breakpoint-sm - 1px)) {
        @content;
    }
}

// TODO - move to the shared library: EONFEH-15903
@mixin app-link($color: v(color-primary)) {
    color: $color;
    font-weight: bold;
    text-decoration: underline;

    @include hover {
        text-decoration: none;
    }
}

@mixin section-title {
    font-weight: 300;
    font-size: 1.625rem;
    margin: 1.25rem 0;
}

@mixin cancel-button {
    color: v(color-white);
    background-color: v(color-grey-button);
}

@mixin cancel-button-hover {
    background-color: v(color-grey-button);
}

@mixin animated-cancel-button {
    @include cancel-button;

    background: linear-gradient(
        to right,
        v(color-grey-hover) 50%,
        v(color-grey-button) 50%
    );
    background-size: 200% 100%;
    background-position: right bottom;
    transition: all ease 1s;

    &:hover {
        @include cancel-button-hover;
        background-position: left bottom;
    }
}

@mixin close-button-position {
    position: absolute;
    top: 0;
    right: 0;
}

@mixin auto-height {
    height: auto !important;
}

.menu {
    font-size: 0.75rem;
    height: 100%;
    position: relative;
    padding-bottom: 3.75rem;
    transition: margin-left v(timing) v(easing);

    ul {
        height: 100%;
        padding: 0;
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }
}

.item {
    > a {
        font-size: 100%;
        display: flex;
        min-height: 3rem;
        color: v(color-base);
        text-overflow: ellipsis;

        > div {
            color: inherit;
            font-weight: inherit;

            > svg {
                fill: v(color-grey);
            }

            > span {
                font-size: 100%;
                font-weight: inherit;
            }
        }

        &:hover {
            color: v(color-turquoise);
            background-color: v(color-white);
        }
    }
}

.active {
    color: v(color-turquoise) !important;
    font-weight: 700;

    > div {
        > svg {
            fill: currentColor !important;
        }
    }
}

.heading {
    min-height: 3rem;
    color: v(color-base);
    text-overflow: ellipsis;
    padding-left: 1rem;

    > div {
        opacity: 0.5;

        span {
            font-weight: 700;
        }
    }

    &:hover {
        color: v(color-base);
        background-color: transparent;
    }

    &__title {
        color: v(color-text-black);
        opacity: 1 !important;
    }
}

.text {
    color: v(color-grey);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    font-weight: 500;

    span {
        display: block;
        padding: 1.25rem 1.75rem;
    }
}
